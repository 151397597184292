<template>
  <div
    class="grid items-center gap-4 bg-white px-4 py-4 sm:grid-cols-[12rem_1fr_24rem_14rem] sm:px-5"
  >
    <div>
      <p
        class="inline-flex h-fit rounded-full bg-gray-100 px-2 text-xs font-medium leading-5 text-gray-800"
      >
        {{ timeAgo.format(new Date(invoice.createdDate)) }}
      </p>
      <p class="mt-1 flex items-center justify-between text-xs text-gray-500">
        {{ createdDate }}
      </p>
    </div>
    <div>
      <p class="mt-1 flex items-center text-sm text-gray-600">
        {{ dateRange }}
      </p>
    </div>

    <div>
      <p class="mt-1 flex items-center justify-between text-sm text-indigo-600">
        {{
          t('company.subscription.invoice.usages', {
            count: invoice.featureUsages.length,
          })
        }}
      </p>
    </div>
    <div class="flex gap-x-4">
      <p
        class="mt-1 flex items-center justify-between break-all text-xs text-gray-500"
      >
        {{ invoice.createdBy }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { format } from 'date-fns'
import TimeAgo from 'javascript-time-ago'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  invoice: {
    required: true,
    type: Object,
  },
})

const timeAgo = new TimeAgo()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const createdDate = computed(() => {
  return format(props.invoice.createdDate, 'dd/MM/yyyy, HH:mm')
})

const dateRange = computed(() => {
  return `${format(props.invoice.startDate, 'dd/MM/yyyy')} - ${format(props.invoice.endDate, 'dd/MM/yyyy')}`
})
</script>
