<template>
  <TransitionRoot appear :show="opened" as="template">
    <Dialog
      as="div"
      class="relative z-10"
      :initialFocus="formContainer"
      @close="closeModal"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-200 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0 bg-black/50"></div>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-gray-50 p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="flex text-lg font-medium leading-6 text-gray-900"
              >
                {{ t('company.subscription.invoice.modalTitle') }}

                <button class="ml-auto" @click="closeModal">
                  <XMarkIcon class="h-6 w-6 text-gray-500" />
                </button>
              </DialogTitle>
              <div ref="formContainer" class="mt-4">
                <div class="flex flex-auto flex-col gap-x-4">
                  <label
                    for="invoicePeriod"
                    class="text-sm font-semibold leading-6 text-gray-900"
                  >
                    {{ t('company.subscription.period') }}</label
                  >

                  <DatePicker
                    id="invoicePeriod"
                    range
                    :value="invoicePeriod"
                    type="dateTime"
                    format="DD MMMM YYYY"
                    inputClass="focus:border-indigo-500 border-gray-300 disabled:bg-gray-100 focus:border-indigo-500 focus:ring-indigo-500 w-full block rounded-md shadow-sm focus:outline-none sm:text-sm"
                    :clearable="true"
                    :editable="false"
                    @change="updateDateRange"
                  />
                </div>
                <fieldset v-if="features.length > 1" class="mt-2">
                  <legend class="text-sm font-medium leading-6 text-gray-900">
                    {{ t('company.subscription.invoice.features') }}
                  </legend>
                  <div
                    class="mt-2border-gray-300 space-y-1 rounded-md border bg-white p-4 text-sm"
                  >
                    <div
                      v-for="(feature, index) in features"
                      :key="feature.id"
                      class="flex items-center gap-x-2"
                    >
                      <input
                        :id="`feature-checkbox-${index}`"
                        v-model="feature.checked"
                        :name="feature.id"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label :for="`feature-checkbox-${index}`">{{
                        feature.description
                      }}</label>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="mt-4 flex justify-end gap-4">
                <FormSave
                  :validationErrors="v$.$errors"
                  :validationTouch="v$.$touch"
                  storeAction="addInvoice"
                  storeName="subscription"
                  :additionalParams="{
                    invoicePeriod,
                    features: features.filter((feature) => feature.checked),
                  }"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import DatePicker from 'vue-datepicker-next'
import { XMarkIcon } from '@heroicons/vue/20/solid/index.js'
import { nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import FormSave from '../FormSave.vue'
import { endOfDay } from 'date-fns'
import { useSubscriptionStore } from '../../store/subscriptionStore.js'

const opened = defineModel({ type: Boolean })
const emit = defineEmits(['invoiceCreated'])

const subscriptionStore = useSubscriptionStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const formContainer = ref(null)
const features = ref([])

const invoicePeriod = ref([null, null])
const updateDateRange = (date) => {
  invoicePeriod.value[0] = date[0]
  invoicePeriod.value[1] = endOfDay(date[1])
}
const validatePeriod = (period) => {
  return period[0] instanceof Date && period[1] instanceof Date
}
const atLeastOneChecked = (value) =>
  value.filter((item) => item.checked).length > 0

const v$ = useVuelidate(
  {
    invoicePeriod: {
      required: helpers.withMessage(
        t('company.subscription.validations.emptyInvoicePeriod'),
        validatePeriod,
      ),
    },
    features: {
      atLeastOneChecked: helpers.withMessage(
        t('company.subscription.validations.noFeaturesSelected'),
        atLeastOneChecked,
      ),
    },
  },
  { invoicePeriod, features },
  { $stopPropagation: true },
)

const closeModal = () => {
  opened.value = false
  nextTick(() => {
    invoicePeriod.value = [null, null]
    features.value = []
    v$.value.$reset()
  })
}

watch(opened, (isOpened) => {
  if (isOpened) {
    subscriptionStore.selectedSubscription.features?.forEach((feature) => {
      if (feature.id !== 'new') {
        features.value.push({
          ...feature,
          description: `${t(`subscriptions.${feature.slug}`)} ${feature.resources
            .map((resource) => resource.name)
            .join(', ')}`,
          checked: true,
        })
      }
    })
    if (!features.value.length) {
      v$.value.features.$touch()
    }
  }
})

watch(
  () => subscriptionStore.isSavedSuccessfully,
  (saved) => {
    if (saved) {
      closeModal()
      emit('invoiceCreated')
    }
  },
)
</script>

<style scoped>
:deep(div.mb-4) {
  margin-bottom: 0;
}
</style>
