import { defineStore } from 'pinia'
import vacancyImporter from '../api/vacancyImporter'

const emptyconfig = {
  ats: '',
  targetSites: [],
  cutoffDate: null,
  customization: [],
  archived: false,
  triggerType: 'MANUAL',
  sourceOfTruth: 'ATS',
  cronSchedule: '0 */30 * * * *',
}

const availableTriggerTypes = {
  recruitee: [
    { label: 'Manual', value: 'MANUAL' },
    { label: 'Schedule', value: 'SCHEDULE' },
    { label: 'Webhook', value: 'WEBHOOK' },
  ],

  connexys: [
    { label: 'Manual', value: 'MANUAL' },
    { label: 'Schedule', value: 'SCHEDULE' },
  ],

  'solutions-factory': [
    { label: 'Manual', value: 'MANUAL' },
    { label: 'Schedule', value: 'SCHEDULE' },
  ],
}

const availableSourceOfTruth = [
  { label: 'From ATS', value: 'ATS' },
  { label: 'From Publisher', value: 'JOBSREPUBLIC' },
]

const availableIntervals = [
  { label: 'Every 15 mins', value: '0 */15 * * * *' },
  { label: 'Every 30 mins', value: '0 */30 * * * *' },
  { label: 'Every 60 mins', value: '0 */60 * * * *' },
]
export const useImporterStore = defineStore('importerStore', {
  state: () => ({
    isLoading: false,
    isSaving: false,
    errors: [],
    isSavedSuccessfully: false,
    configs: [],
    activeConfig: JSON.parse(JSON.stringify(emptyconfig)), // we make sure to use a new object, not a reference,
    events: [],
    selectedEvent: null,
    availableTriggerTypes,
    availableIntervals: availableIntervals,
    availableSourceOfTruth: availableSourceOfTruth,
    availableATS: [
      { label: 'Connexys', value: 'connexys' },
      { label: 'Recruitee', value: 'recruitee' },
      { label: 'Solutions Factory', value: 'solutions-factory' },
    ],
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async loadConfig(companyId) {
      this.configs = []
      this.activeConfig = JSON.parse(JSON.stringify(emptyconfig))
      this.errors = []
      this.selectedEvent = null

      if (!companyId) return

      this.isLoading = true
      try {
        const config =
          await vacancyImporter.getVacancyImportConfiguration(companyId)
        if (config?.data?.length) {
          this.configs = config.data
          this.activeConfig = config.data[0]
        } else {
          this.activeConfig = emptyconfig
        }
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isLoading = false
      }
    },
    async saveConfig(companyId) {
      try {
        this.isSaving = true
        this.errors = []
        this.isSavedSuccessfully = false

        const targetSites = this.activeConfig.targetSites.map((site) => site.id)
        const configToSend = {
          ...this.activeConfig,
          targetSites,
        }
        if (configToSend.triggerType !== 'SCHEDULE') {
          configToSend.cronSchedule = undefined
        }

        if (this.activeConfig.id) {
          await vacancyImporter.putVacancyImportConfiguration(configToSend)
        } else {
          const savedConfig =
            await vacancyImporter.postVacancyImportConfiguration({
              companyId,
              ...configToSend,
            })

          this.activeConfig.id = savedConfig.data.id
          this.configs[this.configs.length - 1] = this.activeConfig
        }
        this.isSavedSuccessfully = true
      } catch (error) {
        this.errors = [error]
      } finally {
        this.isSaving = false
      }
    },
    changeActiveConfig(index) {
      this.activeConfig = this.configs[index]
      this.errors = []
      this.selectedEvent = null
      this.isSavedSuccessfully = false
    },
    async executeImport(companyId) {
      try {
        this.isLoading = true
        if (!this.activeConfig.id) {
          return
        }

        const { data } = await vacancyImporter.postImport({
          companyId,
          ats: this.activeConfig.ats,
        })
        this.selectedEvent = this.processEvent(data)
      } catch (error) {
        this.errors = [error]
      } finally {
        this.isLoading = false
      }
    },
    async loadEvents(companyId) {
      try {
        this.isLoading = true
        this.events = []
        this.selectedEvent = null

        if (!companyId) {
          return
        }

        const { data, meta } = await vacancyImporter.getImports({
          companyId,
          page: this.page.number,
          size: this.page.size,
          sortBy: 'dateCreated',
          sortOrder: 'desc',
        })

        this.events = data.map((event) => this.processEvent(event))
        this.page = meta.page
      } catch (error) {
        this.errors = [error]
      } finally {
        this.isLoading = false
      }
    },
    async loadEvent(eventId) {
      try {
        this.isLoading = true
        this.selectedEvent = null
        if (!eventId) {
          return
        }

        const { data } = await vacancyImporter.getImport(eventId)
        this.selectedEvent = this.processEvent(data)
      } catch (error) {
        this.errors = [error]
      } finally {
        this.isLoading = false
      }
    },
    processEvent(event) {
      // Group vacancies
      const created = []
      const updated = []
      const archived = []
      for (const vacancyEvent of event.vacancyEvents) {
        if (vacancyEvent.type === 'CREATED') {
          created.push(vacancyEvent)
        } else if (vacancyEvent.type === 'UPDATED') {
          updated.push(vacancyEvent)
        } else if (vacancyEvent.type === 'CLOSED') {
          archived.push(vacancyEvent)
        }
      }

      return {
        ...event,
        vacancyEvents: { created, updated, archived },
      }
    },
    addConfig() {
      const lastConfig = this.configs[this.configs.length - 1]
      // You can only have one unsaved config at a time
      if (lastConfig.id) {
        const emptyConfig = JSON.parse(JSON.stringify(emptyconfig))
        this.activeConfig = emptyConfig
        this.configs.push(emptyConfig)
        this.errors = []
        this.selectedEvent = null
        this.isSavedSuccessfully = false
      }
    },
    resetConfig() {
      this.activeConfig = JSON.parse(JSON.stringify(emptyconfig))
    },
    async testAtsConfiguration(companyId) {
      try {
        this.isLoading = true
        this.errors = []
        this.selectedEvent = null

        const targetSites = this.activeConfig.targetSites.map((site) => site.id)
        const configToSend = {
          ...this.activeConfig,
          targetSites,
        }
        if (configToSend.triggerType !== 'SCHEDULE') {
          configToSend.cronSchedule = undefined
        }

        const { data } = await vacancyImporter.postDryImport({
          companyId,
          ...configToSend,
        })
        this.selectedEvent = {
          ...this.processEvent(data),
          mock: true,
        }
        if (this.selectedEvent.error) {
          this.errors = [this.selectedEvent.error]
        }
      } catch (error) {
        this.errors = [error]
      } finally {
        this.isLoading = false
      }
    },
  },
})
