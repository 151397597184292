<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ListHeader
        :title="t('menu.vacancies')"
        :isLoading="vacancyListStore.isLoading"
        :enableSearch="!isEmpty"
        :searchPlaceholder="t('vacancies.searchPlaceholder')"
        :searchTerm="vacancyListStore.filter"
        @update:search-term="updateFilter"
      >
        <router-link
          v-if="settingsStore.activePublisher.id || !authStore.isAdmin"
          to="/vacancies/new/vacancy"
          class="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {{ t('vacancies.createNew') }}
        </router-link>
      </ListHeader>
      <EmptyStateCard
        v-if="isEmpty"
        class="mt-5"
        :title="t('menu.vacancies')"
        :message="t('vacancy.emptyVacancies')"
      >
        <template #icon>
          <ClipboardDocumentListIcon class="" />
        </template>
      </EmptyStateCard>

      <ErrorMessage
        v-if="vacancyListStore.error"
        :error="vacancyListStore.error"
      />
      <div
        v-if="!isEmpty"
        class="mb-4 flex flex-wrap gap-6 rounded-md pl-0.5 md:flex-row"
      >
        <div class="flex w-full items-center gap-2 md:w-auto">
          <div class="flex items-center gap-2">
            <input
              id="excludeTestCheck"
              :checked="vacancyListStore.includeArchived"
              type="checkbox"
              @change="toggleIncludeArchived"
            />
            <label
              for="excludeTestCheck"
              class="inline-flex items-center gap-1 text-sm font-medium text-gray-700"
            >
              {{ t('include') }}
              <span
                class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                >{{ t('archived') }}</span
              ></label
            >
          </div>
        </div>
      </div>

      <List
        v-if="!isEmpty"
        v-slot="{ entity: vacancy }"
        :sortOptions="[
          /* add sort options when sorting is ready on BE*/
        ]"
        :entities="vacancyListStore.vacancies"
        :page="vacancyListStore.page"
        :updatePageNumber="updatePageNumber"
        :updatePageSize="updatePageSize"
        @sort="onSort"
      >
        <router-link
          v-slot="{ href, navigate }"
          :to="`/vacancies/${vacancy.id}/vacancy`"
          custom
        >
          <a
            :href="href"
            class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
            @click="wrapNavigate(navigate, $event, vacancy)"
          >
            <div class="px-4 py-4 sm:px-6">
              <div class="flex items-center justify-between">
                <p class="truncate text-sm font-medium text-indigo-600">
                  {{ vacancy.title }}
                </p>
                <div class="ml-auto flex flex-col gap-1 md:flex-row">
                  <span
                    v-if="vacancy.archived"
                    class="ml-0 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 xl:ml-auto"
                    >{{ t('archived') }}</span
                  >
                  <span
                    v-show="vacancy?.published"
                    class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
                  >
                    {{ t('vacancy.publication.badges.published') }}
                  </span>
                  <div class="flex shrink-0"></div>
                  <p
                    class="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800"
                  >
                    № {{ vacancy.id }}
                  </p>
                </div>
              </div>
              <div class="mt-2 sm:flex sm:justify-between">
                <div class="sm:flex">
                  <p class="flex items-center text-xs text-gray-500">
                    {{ vacancy.companyName }}
                  </p>
                </div>
                <div
                  class="mt-2 flex items-center text-xs text-gray-500 sm:mt-0"
                  :set="(publicationDates = sortedVacancyPublications(vacancy))"
                >
                  <span class="flex gap-x-1">
                    <CalendarIcon class="h-4 w-4" />{{
                      format(vacancy.creationDate, 'dd/MM/yyyy')
                    }}</span
                  >
                  <!-- This is throwing error? Using ONE as publisher -->
                  <span v-if="publicationDates" class="flex gap-x-1">
                    <span class="ml-1 font-medium">-</span>
                    <span v-if="publicationDates.isNoExpiring" class="italic">{{
                      t('vacancy.publication.noEndDate')
                    }}</span>
                    <span v-else>{{
                      format(
                        parseISO(publicationDates.publications[0].endDate),
                        'dd/MM/yyy',
                      )
                    }}</span>
                  </span>
                  <span
                    v-if="publicationDates && !publicationDates.hasSameEndDates"
                    class="ml-1"
                    >{{
                      t('vacancy.publication.publicationCount', {
                        count: publicationDates.publications.length - 1,
                      })
                    }}</span
                  >
                </div>
              </div>
            </div></a
          >
        </router-link>
      </List>
    </div>
  </div>
</template>

<script setup>
import ListHeader from '../components/ListHeader.vue'
import List from '../components/List.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import EmptyStateCard from '../components/EmptyStateCard.vue'
import {
  ClipboardDocumentListIcon,
  CalendarIcon,
} from '@heroicons/vue/24/outline'
import { useVacancyListStore } from '../store/vacancyListStore'
import { useSettingsStore } from '../store/settingsStore'
import { useAuthStore } from '../store/authStore'
import { computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { format, parseISO, getYear } from 'date-fns'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const vacancyListStore = useVacancyListStore()
const settingsStore = useSettingsStore()
const authStore = useAuthStore()
const isEmpty = computed(
  () =>
    !vacancyListStore.isLoading &&
    vacancyListStore.filter === '' &&
    vacancyListStore.vacancies.length === 0,
)

vacancyListStore.load()

const wrapNavigate = (navigate, event, vacancy) => {
  if (!settingsStore.activePublisher.id && vacancy.publisherId) {
    const publisher = settingsStore.publisherList.find(
      (publisher) => publisher.id === vacancy.publisherId,
    )
    settingsStore.activePublisher = publisher
    settingsStore.userRoleChanged = true
  }

  nextTick(() => {
    navigate(event)
  })
}

const areEndDatesSame = (arr) => {
  if (arr.length === 0) {
    return true
  }

  const firstEndDate = arr[0].endDate

  for (let i = 1; i < arr.length; i++) {
    if (arr[i].endDate !== firstEndDate) {
      return false
    }
  }

  return true
}

const sortedVacancyPublications = (vacancy) => {
  if (vacancy?.publications?.length) {
    const publications = vacancy.publications.sort(
      (a, b) => new Date(a.endDate) - new Date(b.endDate),
    )
    const isNoExpiring = getYear(parseISO(publications[0].endDate)) >= 2900
    const hasSameEndDates = areEndDatesSame(publications)
    return { publications, hasSameEndDates, isNoExpiring }
  }
  return null
}

const onSort = (params) => {
  vacancyListStore.sortBy = params.value
  vacancyListStore.sortOrder = params.order
  vacancyListStore.load()
}
const updateFilter = (filter) => {
  vacancyListStore.filter = filter
  vacancyListStore.page.number = 1
  vacancyListStore.load()
}
const updatePageNumber = (number) => {
  vacancyListStore.page.number = number
  vacancyListStore.load()
}
const updatePageSize = (pageSize) => {
  vacancyListStore.page.size = pageSize
  vacancyListStore.load()
}
const toggleIncludeArchived = () => {
  vacancyListStore.includeArchived = !vacancyListStore.includeArchived
  vacancyListStore.load()
}
</script>
