<template>
  <div class="grid grid-cols-12 items-start gap-4 text-sm text-gray-700">
    <div class="col-span-12 lg:col-span-6">
      <div class="ml-1 flex items-end gap-x-2">
        <div class="w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="text-gray-600"
            fill="currentColor"
          >
            <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
            />
          </svg>
        </div>
        <label class="block text-sm font-medium text-gray-700">{{
          t('company.facebook')
        }}</label>
      </div>
      <FormInput
        id="facebook"
        type="url"
        :value="facebook?.url"
        :onInput="updateFacebook"
        :rules="websiteUrlRules"
      />
    </div>
    <div class="col-span-12 lg:col-span-6">
      <div class="ml-1 flex items-end gap-x-2">
        <div class="w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="text-gray-600"
            fill="currentColor"
          >
            <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
            />
          </svg>
        </div>
        <label class="block text-sm font-medium text-gray-700">{{
          t('company.instagram')
        }}</label>
      </div>
      <FormInput
        id="instagram"
        type="url"
        :value="instagram?.url"
        :onInput="updateInstagram"
        :rules="websiteUrlRules"
      />
    </div>
    <div class="col-span-12 lg:col-span-6">
      <div class="ml-1 flex items-end gap-x-2">
        <div class="w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="text-gray-600"
            viewBox="0 0 512 512"
            fill="currentColor"
          >
            <path
              d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
            />
          </svg>
        </div>
        <label class="block text-sm font-medium text-gray-700">{{
          t('company.twitter')
        }}</label>
      </div>
      <FormInput
        id="twitter"
        type="url"
        :value="twitter?.url"
        :onInput="updateTwitter"
        :rules="websiteUrlRules"
      />
    </div>
    <div class="col-span-12 lg:col-span-6">
      <div class="ml-1 flex items-end gap-x-2">
        <div class="w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="text-gray-600"
            fill="currentColor"
          >
            <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
            />
          </svg>
        </div>
        <label class="block text-sm font-medium text-gray-700">{{
          t('company.linkedIn')
        }}</label>
      </div>
      <FormInput
        id="linkedIn"
        type="url"
        :value="linkedIn?.url"
        :onInput="updateLinkedIn"
        :rules="websiteUrlRules"
      />
    </div>
    <div class="col-span-12 lg:col-span-6">
      <div class="ml-1 flex items-end gap-x-2">
        <div class="w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="text-gray-600"
            fill="currentColor"
          >
            <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
            />
          </svg>
        </div>
        <label class="block text-sm font-medium text-gray-700">{{
          t('company.tiktok')
        }}</label>
      </div>
      <FormInput
        id="tiktok"
        type="url"
        :value="tiktok?.url"
        :onInput="updateTiktok"
        :rules="websiteUrlRules"
      />
    </div>
    <div class="col-span-12 lg:col-span-6">
      <div class="ml-1 flex items-end gap-x-2">
        <div class="h-6 w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="text-gray-600"
            viewBox="0 0 576 512"
            fill="currentColor"
          >
            <path
              d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"
            />
          </svg>
        </div>
        <label class="block text-sm font-medium text-gray-700">{{
          t('company.youtube')
        }}</label>
      </div>
      <FormInput
        id="youtube"
        type="url"
        :value="youtube?.url"
        :onInput="updateYoutube"
        :rules="websiteUrlRules"
      />
    </div>
  </div>
</template>

<script setup>
import FormInput from './FormInput.vue'
import { useCompanyStore } from '../store/companyStore'
import { helpers, url } from '@vuelidate/validators'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const companyStore = useCompanyStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const websiteUrlRules = {
  url: helpers.withMessage(t('validations.invalidUrl'), url),
  $autoDirty: true,
}

const facebook = computed(() =>
  companyStore.company.socialLinks?.find(
    (social) => social.type === 'facebook',
  ),
)
const updateFacebook = (url) => {
  companyStore.updateSocialNetwork('facebook', url)
}

const linkedIn = computed(() =>
  companyStore.company.socialLinks?.find(
    (social) => social.type === 'linkedIn',
  ),
)
const updateLinkedIn = (url) => {
  companyStore.updateSocialNetwork('linkedIn', url)
}

const twitter = computed(() =>
  companyStore.company.socialLinks?.find((social) => social.type === 'twitter'),
)
const updateTwitter = (url) => {
  companyStore.updateSocialNetwork('twitter', url)
}

const instagram = computed(() =>
  companyStore.company.socialLinks?.find(
    (social) => social.type === 'instagram',
  ),
)
const updateInstagram = (url) => {
  companyStore.updateSocialNetwork('instagram', url)
}

const tiktok = computed(() =>
  companyStore.company.socialLinks?.find((social) => social.type === 'tiktok'),
)
const updateTiktok = (url) => {
  companyStore.updateSocialNetwork('tiktok', url)
}

const youtube = computed(() =>
  companyStore.company.socialLinks?.find((social) => social.type === 'youtube'),
)
const updateYoutube = (url) => {
  companyStore.updateSocialNetwork('youtube', url)
}
</script>
