import { defineStore } from 'pinia'
import tooManagement from '../api/tooManagement'

export const useSubscriptionListStore = defineStore('subscriptionListStore', {
  state: () => ({
    isLoading: false,
    error: null,
    subscriptions: [],
    filter: {
      searchTerm: '',
      plan: '',
      state: '',
      startDate: null,
      endDate: null,
    },
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async load() {
      this.error = null
      this.isLoading = true
      this.subscriptions = []

      try {
        const queryParams = {
          page: this.page.number,
          size: this.page.size,
          sortBy: 'endDate',
          sortOrder: 'desc',
        }

        if (this.filter.searchTerm) {
          queryParams.principalName = this.filter.searchTerm
        }
        if (this.filter.plan) {
          queryParams.plan = this.filter.plan
        }
        if (this.filter.state) {
          queryParams.state = this.filter.state
        }
        if (this.filter.startDate) {
          queryParams.startDate = this.filter.startDate.toISOString()
        }
        if (this.filter.endDate) {
          queryParams.endDate = this.filter.endDate.toISOString()
        }

        const subscriptions = await tooManagement.getSubscription(queryParams)
        // Check if this is the page we requested.
        // Useful if a user requests the next page before the previous page was loaded.
        if (subscriptions.meta.page.number === this.page.number) {
          this.subscriptions = subscriptions.data
          this.page = subscriptions.meta.page
        }

        this.isLoading = false
      } catch (error) {
        this.subscriptions = []
        this.isLoading = false
        this.error = error
      }
    },
  },
})
