<template>
  <div
    class="grid items-center gap-4 bg-white px-4 py-4 sm:grid-cols-[10rem_1fr_12rem_10rem_14rem] sm:px-5"
  >
    <div>
      <p
        class="inline-flex h-fit rounded-full bg-gray-100 px-2 text-xs font-medium leading-5 text-gray-800"
      >
        {{ timeAgo.format(new Date(usage.createdDate)) }}
      </p>
      <p class="mt-1 flex items-center justify-between text-xs text-gray-500">
        {{ createdDate }}
      </p>
    </div>
    <div class="max-w-[23rem]">
      <div class="flex">
        <p class="text-sm font-medium text-indigo-600">
          {{ usage.descriptor.vacancy.title }}
        </p>
      </div>
      <p class="mt-1 flex items-center text-xs text-gray-500">
        {{ usage.descriptor.company.name }}
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <div class="flex justify-start gap-x-2">
        <span
          v-show="usage.descriptor.vacancy?.id"
          class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
        >
          № {{ usage.descriptor.vacancy?.id }}
        </span>
        <span
          v-show="usage.descriptor.publication?.published"
          class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
        >
          {{
            startsInFuture
              ? t('vacancy.publication.badges.toBePublished')
              : t('vacancy.publication.badges.published')
          }}
        </span>

        <span
          v-show="!usage.descriptor.publication?.published"
          class="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800"
        >
          {{ t('company.subscription.expired') }}
        </span>
      </div>
      <div class="mt-1 sm:flex sm:justify-between">
        <div class="mt-2 flex items-center text-xs text-gray-500 sm:mt-0">
          <span class="flex gap-x-1">
            <CalendarDaysIcon class="h-4 w-4" />
            {{ startDate }}
          </span>
          <span class="px-0.5">-</span>
          <span>
            {{ endDate }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="text-right text-xs text-gray-500"
      :class="{ invisible: usage.reversed }"
    >
      <p v-show="usage.usedCredits !== 0 || usage.planCredits !== 0">
        {{
          t('company.subscription.amountCreditUsed', {
            count: usage.usedCredits,
            total: usage.planCredits,
          })
        }}
      </p>
      <p v-show="usage.usedCredits !== 0 || usage.planCredits !== 0">
        {{
          t('company.subscription.amountCreditRemaining', {
            count: usage.planCredits - usage.usedCredits,
          })
        }}
      </p>
    </div>
    <div class="flex justify-end gap-x-4">
      <div v-if="usage.invoiced">
        <p class="self-center text-right text-xs font-medium text-green-600">
          {{ t('company.subscription.creditInvoiced') }}
        </p>
        <p class="mt-1 justify-between text-right text-xs text-gray-500">
          {{ lastModifiedDate }}
        </p>
        <p class="justify-between text-right text-xs text-gray-500">
          {{ usage.lastModifiedBy }}
        </p>
      </div>
      <template v-else>
        <button
          v-if="!usage.reversed"
          type="button"
          class="inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-3 text-xs font-semibold"
          :class="
            reversible ? 'cursor-pointer text-indigo-600' : 'text-gray-500'
          "
          :disabled="!reversible || subscriptionStore.isSaving"
          @click="revertUsage"
        >
          <ArrowUturnLeftIcon class="h-4 w-4" aria-hidden="true" />
          {{ t('company.subscription.restoreCredit') }}
          <HelpPopover
            v-if="!reversible"
            :text="t('company.subscription.restoreDisabled')"
            placement="left"
          />
        </button>
        <div v-else>
          <p class="self-center text-right text-xs font-medium text-indigo-600">
            {{ t('company.subscription.creditRestored') }}
          </p>
          <p class="mt-1 justify-between text-right text-xs text-gray-500">
            {{ lastModifiedDate }}
          </p>
          <p class="justify-between text-right text-xs text-gray-500">
            {{ usage.lastModifiedBy }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { format, parseISO } from 'date-fns'
import { CalendarDaysIcon, ArrowUturnLeftIcon } from '@heroicons/vue/24/outline'
import HelpPopover from '../HelpPopover.vue'
import TimeAgo from 'javascript-time-ago'
import { useSubscriptionStore } from '../../store/subscriptionStore'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const subscriptionStore = useSubscriptionStore()
const props = defineProps({
  usage: {
    required: true,
    type: Object,
  },
  reversible: {
    type: Boolean,
    default: false,
  },
})

const timeAgo = new TimeAgo()

const revertUsage = () => {
  subscriptionStore.revertSubscriptionFeatureUsage(props.usage)
}

const createdDate = computed(() => {
  return format(props.usage.createdDate, 'dd/MM/yyyy, HH:mm')
})
const lastModifiedDate = computed(() => {
  return props.usage.lastModifiedDate
    ? format(props.usage.lastModifiedDate, 'dd/MM/yyyy, HH:mm')
    : ''
})
const startDate = computed(() => {
  return format(props.usage.descriptor.publication.startDate, 'dd/MM/yyyy')
})
const endDate = computed(() => {
  if (props.usage.descriptor.publication.endDate.startsWith('2999'))
    return t('vacancy.publication.noEndDate')

  return format(
    parseISO(props.usage.descriptor.publication.endDate),
    'dd/MM/yyyy',
  )
})
const startsInFuture = computed(() => {
  const startDate = parseISO(props.usage.descriptor.publication.startDate)
  return startDate > new Date()
})
</script>
