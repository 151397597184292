import { get, post, put, del } from './common'

const getCompanies = async (searchParams) =>
  get('management/v1/companies', searchParams)

const getCompany = async (companyId) =>
  get(`management/v1/companies/${companyId}`)

const putCompany = async (company) =>
  put(`management/v1/companies/${company.id}`, company)

const postCompany = async (company) => post('management/v1/companies', company)

const getSites = async (searchParams) =>
  get('management/v1/sites', searchParams)

const getSite = async (siteId) => get(`management/v1/sites/${siteId}`)

const getVacancies = async (searchParams) =>
  get('management/v1/vacancies', searchParams)

const getVacancy = async (vacancyId) =>
  get(`management/v1/vacancies/${vacancyId}`)

const putSite = async (site) => put(`management/v1/sites/${site.id}`, site)

const postSite = async (site) => post('management/v1/sites', site)

const getPublishers = async (searchParams) =>
  get('management/v1/publishers', searchParams)

const getPublisher = async (publisherId) =>
  get(`management/v1/publishers/${publisherId}`)

const postPublisher = async (publisher) =>
  post('management/v1/publishers', publisher)

const putPublisher = async (publisher) =>
  put(`management/v1/publishers/${publisher.id}`, publisher)

const getUsersForPublisher = async (publisherId, searchParams) =>
  get(`management/v1/publishers/${publisherId}/users`, searchParams)

const inviteUser = async (publisherId, user) =>
  post(`management/v1/publishers/${publisherId}/users`, user)

const deleteUser = async (publisherId, userId) =>
  del(`management/v1/publishers/${publisherId}/users/${userId}`)

const getSitesForCompany = async (companyId) =>
  get(`management/v1/companies/${companyId}/sites`)

const putSitesForCompany = async (companyId, sites) =>
  put(`management/v1/companies/${companyId}/sites`, sites)

const getPublishersForCompany = async (companyId) =>
  get(`management/v1/companies/${companyId}/publishers`)

const putPublishersForCompany = async (companyId, publishers) => {
  const procPublishers = publishers.map((publisher) => ({
    id: publisher.id,
  }))

  return put(`management/v1/companies/${companyId}/publishers`, procPublishers)
}

const enableTopItem = async (vacancyId) =>
  put(`management/v1/vacancies/${vacancyId}/top-jobs`, {})

const disableTopItem = async (vacancyId) =>
  del(`management/v1/vacancies/${vacancyId}/top-jobs`)

const getSalaryScaleGroups = async () =>
  get(`management/v1/salary-scale-groups`)

const getPublisherSalaryScaleGroups = async (publisherId) =>
  get(`management/v1/publishers/${publisherId}/salary-scale-groups`)

const putPublisherSalaryScaleGroups = async (
  publisherId,
  salaryScaleGroups,
) => {
  const salaryScaleGroupIds = salaryScaleGroups.map((salaryScaleGroup) => ({
    value: salaryScaleGroup.id,
  }))
  return put(
    `management/v1/publishers/${publisherId}/salary-scale-groups`,
    salaryScaleGroupIds,
  )
}

const postSalaryScaleGroup = async (salaryScaleGroup) =>
  post('management/v1/salary-scale-groups', salaryScaleGroup)

const putSalaryScaleGroup = async (salaryScaleGroupId, salaryScaleGroup) =>
  put(
    `management/v1/salary-scale-groups/${salaryScaleGroupId}`,
    salaryScaleGroup,
  )

const getSalaryScales = async (salaryScaleGroupId) =>
  get(`management/v1/salary-scale-groups/${salaryScaleGroupId}/salary-scales`)

const putSalaryScale = async (salaryScaleId, salaryScale) =>
  put(`management/v1/salary-scales/${salaryScaleId}`, salaryScale)

const postSalaryScale = async (salaryScale) =>
  post('management/v1/salary-scales', salaryScale)

const getTagGroups = async (siteId) =>
  get(`management/v1/sites/${siteId}/tag-groups`)

const postTagGroup = async (siteId, tagGroup) =>
  post(`management/v1/sites/${siteId}/tag-groups`, tagGroup)

const putTagGroup = async (siteId, tagGroup) =>
  put(`management/v1/sites/${siteId}/tag-groups/${tagGroup.id}`, tagGroup)

const getTags = async (tagGroupId) =>
  get(`management/v1/tag-groups/${tagGroupId}/tags`)

const postTag = async (tag, tagGroupId) =>
  post(`management/v1/tag-groups/${tagGroupId}/tags`, tag)

const putTag = async (tag, tagGroupId) =>
  put(`management/v1/tag-groups/${tagGroupId}/tags/${tag.id}`, tag)

const postSubscription = async (subscription) =>
  post(`management/v1/subscriptions`, subscription)

const putSubscription = async (subscription) =>
  put(`management/v1/subscriptions/${subscription.id}`, subscription)

const putSubscriptionState = async (subscriptionId, state) =>
  put(`management/v1/subscriptions/${subscriptionId}/state`, state)

const getSubscription = async (params) =>
  get('management/v1/subscriptions', params)

const getSubscriptionSnapshots = async (subscriptionId, params) =>
  get(`management/v1/subscriptions/${subscriptionId}/snapshots`, params)

const getSubscriptionFeature = async (featureId) =>
  get(`management/v1/subscription-features/${featureId}`)

const postSubscriptionFeature = async (feature) =>
  post('management/v1/subscription-features', feature)

const initializeSubscriptionFeature = async (featureId) =>
  put(`management/v1/subscription-features/${featureId}/initialize`)

const putSubscriptionFeature = async (feature) =>
  put(`management/v1/subscription-features/${feature.id}`, feature)

const getSubscriptionFeatureUsages = async (params) =>
  get('management/v1/subscription-feature-usages', params)

const getSubscriptionFeatureSnapshots = async (featureId, params) =>
  get(`management/v1/subscription-features/${featureId}/snapshots`, params)

const revertSubscriptionFeatureUsage = async (featureUsageId) =>
  put(`management/v1/subscription-feature-usages/${featureUsageId}/revert`)

const getSubscriptionInvoices = async (params) =>
  get(`management/v1/subscription-invoices/`, params)

const postSubscriptionInvoice = async (invoice) =>
  post(`management/v1/subscription-invoices`, invoice)

const getJobAlerts = async (params) => get(`management/v1/jobalerts`, params)

const getJobAlert = async (id) => get(`management/v1/jobalerts/${id}`)

const getJobAlertVacancies = async (id, params) =>
  get(`management/v1/jobalerts/${id}/vacancies`, params)

const deleteJobAlerts = async (values) => del(`management/v1/jobalerts`, values)

export default {
  getSitesForCompany,
  putSitesForCompany,
  getPublishersForCompany,
  putPublishersForCompany,
  getUsersForPublisher,
  inviteUser,
  deleteUser,
  getCompanies,
  getCompany,
  putCompany,
  postCompany,
  getSites,
  getSite,
  putSite,
  postSite,
  getVacancies,
  getVacancy,
  getPublishers,
  getPublisher,
  postPublisher,
  putPublisher,
  enableTopItem,
  disableTopItem,
  getSalaryScaleGroups,
  getPublisherSalaryScaleGroups,
  putPublisherSalaryScaleGroups,
  putSalaryScaleGroup,
  postSalaryScaleGroup,
  postSalaryScale,
  putSalaryScale,
  getSalaryScales,
  getTagGroups,
  postTagGroup,
  putTagGroup,
  getTags,
  postTag,
  putTag,
  postSubscription,
  putSubscription,
  putSubscriptionState,
  getSubscription,
  getSubscriptionSnapshots,
  getSubscriptionFeature,
  putSubscriptionFeature,
  postSubscriptionFeature,
  initializeSubscriptionFeature,
  getSubscriptionFeatureUsages,
  getSubscriptionFeatureSnapshots,
  revertSubscriptionFeatureUsage,
  getSubscriptionInvoices,
  postSubscriptionInvoice,
  getJobAlerts,
  getJobAlert,
  getJobAlertVacancies,
  deleteJobAlerts,
}
