<template>
  <div class="pb-5 sm:flex sm:items-center sm:justify-between">
    <h1 class="text-2xl font-semibold text-gray-900">
      {{ title }}
      <LoadingSpinner v-if="isLoading" class="inline h-5 w-5 text-gray-400" />
    </h1>

    <div class="mt-3 sm:ml-4 sm:mt-0">
      <label v-if="enableSearch" for="search" class="sr-only">{{
        t('search')
      }}</label>
      <div class="flex rounded-md shadow-sm">
        <div
          v-if="enableSearch"
          class="relative grow self-center focus-within:z-10"
        >
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <MagnifyingGlassIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            id="searchMobile"
            v-debounce:500ms="(value) => $emit('update:searchTerm', value)"
            type="search"
            :value="searchTerm"
            name="searchMobile"
            class="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:hidden"
            :placeholder="placeholder"
          />
          <input
            id="search"
            ref="searchInput"
            v-debounce:500ms="(value) => $emit('update:searchTerm', value)"
            type="search"
            :value="searchTerm"
            name="search"
            class="hidden w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
            :placeholder="placeholder"
          />
          <div
            v-if="!searchTerm"
            class="absolute inset-y-0 right-0 flex hidden py-1.5 pr-1.5 sm:block"
          >
            <kbd
              class="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400"
            >
              Ctrl /
            </kbd>
          </div>
        </div>
        <slot></slot>
        <!-- <button
              type="button"
              class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <BarsArrowUpIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
              <span class="ml-2">Sort</span>
              <ChevronDownIcon
                class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import vueDebounce from 'vue-debounce'
import {
  // ChevronDownIcon,
  MagnifyingGlassIcon,
  // BarsArrowUpIcon,
} from '@heroicons/vue/20/solid'
import LoadingSpinner from './LoadingSpinner.vue'
import { useI18n } from 'vue-i18n'
import { ref, computed, onBeforeUnmount } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  enableSorting: {
    type: Boolean,
    default: false,
  },
  enableSearch: {
    type: Boolean,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    default: '',
  },
  searchTerm: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['update:searchTerm'])

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const vDebounce = vueDebounce({
  lock: true,
  fireOnEmpty: true,
  listenTo: 'input',
})

const placeholder = computed(() =>
  props.searchPlaceholder ? props.searchPlaceholder : t('search'),
)

// Setup Ctrl+/ shortcut
const searchInput = ref(null)

const onKeydown = (event) => {
  if (event.key === '/' && event.ctrlKey) {
    searchInput.value?.focus()
  }
}

window.addEventListener('keydown', onKeydown)

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeydown)
})
</script>
