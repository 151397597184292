import { defineStore } from 'pinia'
import router from '../router.js'
import tooManagement from '../api/tooManagement'
import { postImage } from '../api/common'
import { theme } from '../utils/theme.js'
import uuidv4 from '../utils/uuidv4.js'

const emptySite = {
  id: null,
  googleTagManagerId: 'GTM-5XD343G', // Default workspace in GTM
  // Using Gemeentebanen colors as default
  theme,
}

const formatSite = (site) => {
  if (!site.theme) {
    site.theme = { ...emptySite.theme }
  } else if (typeof site.theme === 'string') {
    const unescapedString = JSON.parse(site.theme)
    const theme = JSON.parse(unescapedString)
    site.theme = { ...emptySite.theme, ...theme }
  }
  return site
}

export const useSiteStore = defineStore('siteStore', {
  state: () => ({
    isLoading: false,
    isSaving: false,
    errors: [],
    tagGroupsCategories: [
      { value: 'VACANCY_EDUCATION', name: 'Education' },
      { value: 'VACANCY_WORKFIELD', name: 'Work field' },
      { value: 'VACANCY_WORKCATEGORY', name: 'Work category' },
      { value: 'VACANCY_WORKEXPERIENCE', name: 'Work experience' },
      { value: 'VACANCY_EMPLOYMENTTYPE', name: 'Employment type' },
    ],
    tagGroups: [],
    tags: [],
    tagErrors: [],
    isSavedSuccessfully: false,
    site: JSON.parse(JSON.stringify(emptySite)),
    fullLogoImage: null,
    faviconImage: null,
  }),
  actions: {
    async load(siteId) {
      this.isLoading = true
      this.resetState()
      try {
        if (siteId === 'new') {
          await this.setupNewSite()
        } else {
          await this.loadExistingSite(siteId)
        }
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
    async loadTagGroups() {
      this.tagGroups = []
      this.tagErrors = []
      this.isLoading = true
      try {
        const tagGroups = await tooManagement.getTagGroups(this.site.id)
        this.tagGroups = tagGroups.data
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
    async loadTags(tagGroupId) {
      this.tags = []
      this.tagErrors = []
      this.isLoading = true
      try {
        const tags = await tooManagement.getTags(tagGroupId)
        this.tags = tags.data
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
    async addTagGroup(tagGroup) {
      this.tagErrors = []
      this.isSaving = true
      try {
        const savedGroup = await tooManagement.postTagGroup(
          this.site.id,
          tagGroup,
        )
        this.tagGroups.push(savedGroup.data)
        this.isSavedSuccessfully = true
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isSaving = false
      }
    },
    async editTagGroup(tagGroup) {
      this.tagErrors = []
      this.isSaving = true
      try {
        const savedGroup = await tooManagement.putTagGroup(
          this.site.id,
          tagGroup,
        )
        const index = this.tagGroups.findIndex(
          (group) => group.id === savedGroup.data.id,
        )
        this.tagGroups[index] = savedGroup.data
        this.isSavedSuccessfully = true
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isSaving = false
      }
    },
    async editTag(tag, tagGroupId) {
      this.tagErrors = []
      this.isSaving = true
      try {
        const savedTag = await tooManagement.putTag(tag, tagGroupId)
        const index = this.tags.findIndex((tag) => tag.id === savedTag.data.id)
        this.tags[index] = savedTag.data
        this.isSavedSuccessfully = true
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isSaving = false
      }
    },
    async addTag(tag, tagGroupId) {
      this.tagErrors = []
      this.isSaving = true
      try {
        const savedTag = await tooManagement.postTag(tag, tagGroupId)
        this.tags.push(savedTag.data)
        this.isSavedSuccessfully = true
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isSaving = false
      }
    },
    async save() {
      this.isSaving = true
      this.errors = []
      this.isSavedSuccessfully = false

      try {
        await this.saveImages()
        await this.saveSiteData()
        this.isSavedSuccessfully = true
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isSaving = false
      }
    },
    async saveImages() {
      try {
        if (this.fullLogoImage) {
          const fullLogoUrl = await this.uploadImage(this.fullLogoImage)
          this.site.fullLogoUrl = fullLogoUrl.data.url
        }
        if (this.faviconImage) {
          const faviconUrl = await this.uploadImage(this.faviconImage)
          this.site.faviconUrl = faviconUrl.data.url
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async uploadImage(image) {
      return postImage('management/v1/images', image)
    },
    async saveSiteData() {
      const siteToSave = { ...this.site }
      if (siteToSave.theme) {
        const themeStr = JSON.stringify(siteToSave.theme)
        const escapedThemeStr = JSON.stringify(themeStr)
        siteToSave.theme = escapedThemeStr
      }
      const saveFunction =
        this.site.id === 'new' ? tooManagement.postSite : tooManagement.putSite
      if (this.site.id === 'new') {
        delete siteToSave.id // Remove 'id' for new site
      }
      const savedSite = await saveFunction(siteToSave)
      if (this.site.id === 'new') {
        router.replace(`/sites/${savedSite.data.id}/site`)
      }
      this.site = formatSite(savedSite.data)
      this.isSavedSuccessfully = true
    },
    async setupNewSite() {
      this.site = {
        ...JSON.parse(JSON.stringify(emptySite)),
        token: uuidv4(),
        id: 'new',
      }
    },
    async loadExistingSite(siteId) {
      const site = await tooManagement.getSite(siteId)
      this.site = formatSite(site.data)
      await this.loadTagGroups()
    },
    resetState() {
      this.tagGroups = []
      this.tags = []
      this.tagErrors = []
      this.isSavedSuccessfully = false
      this.fullLogoImage = null
      this.faviconImage = null
    },
    handleError(error) {
      this.errors.push(error)
    },
  },
})
