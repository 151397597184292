<template>
  <div
    class="mb-12 mt-4 grid grid-cols-9 items-center gap-x-4 gap-y-2 rounded-md"
  >
    <div class="col-span-9 flex gap-x-4">
      <div class="grow">
        <label for="search" class="sr-only text-sm font-medium text-gray-700">{{
          t('search')
        }}</label>
        <div class="flex rounded-md shadow-sm">
          <div class="relative grow self-center focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              ref="searchInput"
              v-debounce:500ms="filterSubscriptions"
              type="search"
              :value="subscriptionListStore.filter.searchTerm"
              name="search"
              class="block w-full rounded-md border-gray-300 pl-10 text-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Search subscriptions"
            />
            <div
              v-if="!subscriptionListStore.filter.searchTerm"
              class="absolute inset-y-0 right-0 hidden py-1.5 pr-1.5 sm:block"
            >
              <kbd
                class="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400"
              >
                Ctrl /
              </kbd>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-9 ml-1 lg:col-span-2">
      <Listbox
        v-model="plan"
        as="div"
        class="flex flex-col gap-x-2 lg:flex-row lg:items-center"
        @update:model-value="updateFilters(true)"
      >
        <ListboxLabel class="block shrink-0 text-sm font-medium text-gray-700">
          {{ t('subscriptions.plan') }}
        </ListboxLabel>
        <div class="relative md:w-full">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          >
            <span class="ml-2 block truncate capitalize">{{
              plan?.toLowerCase() || t('candidates.filters.all')
            }}</span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leaveActiveClass="transition ease-in duration-100"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                v-for="(type, index) in plans"
                :key="index"
                v-slot="{ active, selected }"
                as="template"
                :value="type"
              >
                <li
                  :class="[
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate capitalize',
                    ]"
                  >
                    {{ type?.toLowerCase() || t('candidates.filters.all') }}
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div class="col-span-9 lg:col-span-2">
      <Listbox
        v-model="state"
        as="div"
        class="flex w-full flex-col gap-x-2 lg:flex-row lg:items-center"
        @update:model-value="updateFilters(true)"
      >
        <ListboxLabel class="block shrink-0 text-sm font-medium text-gray-700">
          {{ t('candidates.filters.status') }}
        </ListboxLabel>
        <div class="relative md:w-full">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          >
            <span class="flex h-6 items-center">
              <span class="ml-2 block truncate capitalize">{{
                state?.toLowerCase() || t('candidates.filters.all')
              }}</span>
            </span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leaveActiveClass="transition ease-in duration-100"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                v-for="(status, index) in states"
                :key="index"
                v-slot="{ active, selected }"
                as="template"
                :value="status"
              >
                <li
                  :class="[
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                  ]"
                >
                  <span
                    class="block truncate capitalize"
                    :class="[selected ? 'font-semibold' : 'font-normal']"
                  >
                    {{ status?.toLowerCase() || t('candidates.filters.all') }}
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div
      class="col-span-9 flex flex-col items-start gap-x-2 lg:col-span-2 lg:flex-row lg:items-center"
    >
      <label
        for="startDate"
        class="block shrink-0 text-sm font-medium text-gray-700"
        >{{ t('subscriptions.startDate') }}</label
      >

      <DatePicker
        id="startDate"
        :value="subscriptionListStore.filter.startDate"
        type="dateTime"
        format="D MMM YYYY"
        inputClass="focus:border-indigo-500 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 w-full block rounded-md shadow-sm focus:outline-none sm:text-sm"
        :clearable="true"
        :editable="false"
        class="mt-1"
        @change="(value) => updateDateRange('startDate', value)"
      />
    </div>
    <div
      class="col-span-9 flex flex-col items-start gap-x-2 lg:col-span-2 lg:flex-row lg:items-center"
    >
      <label
        for="endDate"
        class="block shrink-0 text-sm font-medium text-gray-700"
        >{{ t('subscriptions.endDate') }}</label
      >

      <DatePicker
        id="endDate"
        :value="subscriptionListStore.filter.endDate"
        type="dateTime"
        format="D MMM YYYY"
        inputClass="focus:border-indigo-500 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 w-full block rounded-md shadow-sm focus:outline-none sm:text-sm"
        :clearable="true"
        :editable="false"
        class="mt-1"
        @change="(value) => updateDateRange('endDate', value)"
      />
    </div>
    <div class="col-span-1 ml-auto mt-1">
      <button
        type="button"
        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        @click="updateFilters(false)"
      >
        {{ t('candidates.refresh') }}
        <LoadingSpinner
          v-if="subscriptionListStore.isLoading"
          class="ml-3 h-4 w-4"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useSubscriptionListStore } from '../../store/subscriptionListStore'
import DatePicker from 'vue-datepicker-next'
import vueDebounce from 'vue-debounce'
import 'vue-datepicker-next/index.css'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import router from '../../router'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import LoadingSpinner from '../LoadingSpinner.vue'
import { ChevronUpDownIcon } from '@heroicons/vue/24/outline'
import { endOfDay, setDefaultOptions } from 'date-fns'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'

const subscriptionListStore = useSubscriptionListStore()
subscriptionListStore.$reset()

const route = useRoute()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const vDebounce = vueDebounce({
  lock: true,
  fireOnEmpty: true,
  listenTo: 'input',
})

const plans = ['', 'START', 'BASIC', 'PLUS', 'PREMIUM', 'TEST TRIAL']
const states = ['', 'CREATED', 'ACTIVATED', 'EXPIRED', 'CANCELLED']

const searchInput = ref('')

const plan = computed({
  get() {
    return subscriptionListStore.filter.plan?.replaceAll('_', ' ')
  },
  set(value) {
    subscriptionListStore.filter.plan = value.replaceAll(' ', '_')
  },
})
const state = computed({
  get() {
    return subscriptionListStore.filter.state
  },
  set(value) {
    subscriptionListStore.filter.state = value
  },
})
const updatePageNumber = (number) => {
  subscriptionListStore.page.number = number
  subscriptionListStore.load()
}
const filterSubscriptions = (value) => {
  subscriptionListStore.filter.searchTerm = value
  updatePageNumber(1)
}

const updateFilters = (changeUrl) => {
  updatePageNumber(1)

  if (changeUrl) {
    router.push({
      query: {
        ...route.params.query,
        plan: subscriptionListStore.filter.plan,
        state: subscriptionListStore.filter.state,
        startDate: subscriptionListStore.filter.startDate?.toISOString() || '',
        endDate: subscriptionListStore.filter.endDate?.toISOString() || '',
      },
    })
  }
}

const updateDateRange = (fieldName, value) => {
  subscriptionListStore.filter[fieldName] = value
  if (fieldName === 'endDate' && value) {
    subscriptionListStore.filter[fieldName] = endOfDay(value)
  }
  updateFilters(true)
}

const onKeydown = (event) => {
  if (event.key === '/' && event.ctrlKey) {
    searchInput.value?.focus()
  }
}

window.addEventListener('keydown', onKeydown)

onBeforeMount(() => {
  setDefaultOptions({ weekStartsOn: 1 })
  if (route.query.plan) {
    subscriptionListStore.filter.plan = route.query.plan
  }
  if (route.query.state) {
    subscriptionListStore.filter.state = route.query.state
  }
  if (route.query.startDate) {
    subscriptionListStore.filter.startDate = new Date(route.query.startDate)
  }
  if (route.query.endDate) {
    subscriptionListStore.filter.endDate = new Date(route.query.endDate)
  }

  updateFilters(false)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeydown)
})
</script>
